import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Talk from "../components/KeepInTouch"

function Contact() {
  return (
    <Layout>
      <SEO title={"Contact"}/>
      <div id="contact">
        <div className="contact-header">
          <div className="container">
            <h1>We'd Love to hear from you</h1>
            <p className="m-0">Whether you have a question features, tools, pricing or anything else, our team is ready
              to answer all your questions</p>
          </div>
        </div>
      </div>

      <div className="contact-container container mb-5">
        <div className="contact-info-wrapper row d-flex flex-sm-column flex-lg-row">
          <div className="contact-left col-sm-12 col-lg-12">

            {/*<h1>Contact Us</h1>*/}

            {/*<form className="px-md-5 py-md-5">*/}

            {/*  <p>feel free to contact us and we will get back to you as soon as we can.</p>*/}

            {/*  <div className="form-group">*/}
            {/*    <input type="text" className="form-control contact-input" id="name" placeholder="your name here"/>*/}
            {/*  </div>*/}

            {/*  <div className="form-group">*/}
            {/*    <input type="email" className="form-control contact-input" id="email1" aria-describedby="emailHelp" placeholder="your email here"/>*/}
            {/*  </div>*/}

            {/*  <div className="form-group">*/}
            {/*    <input type="text" className="form-control contact-input" id="textArea" placeholder="tell us all about it"/>*/}
            {/*  </div>*/}

            {/*  <button type="submit" className="btn btn-primary">send</button>*/}
            {/*</form>*/}

            <Talk/>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact